import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box, useTheme, Typography, Alert, Snackbar, Paper, IconButton, Fade
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import axios from '../CommonComponents/axiosConfig';
import ChatSidebar from './ChatSidebar';
import ChatHistory from './ChatHistory';
import ChatInput from './ChatInput';
import ProgressingMessage from './ProgressingMessage';
import { useAuth } from '../AuthenticationComponents/AuthProvider';
import Graph from './Graph';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MenuIcon from '@mui/icons-material/Menu';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
import PushPinIcon from '@mui/icons-material/PushPin';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SIDEBAR_WIDTH = 320;
const RIGHT_PANEL_WIDTH = 430;
const HEADER_HEIGHT = '64px';

const ChatInterface = () => {
  const theme = useTheme();
  const location = useLocation();
  const { agentId } = useParams();
  const { userInfo } = useAuth();
  const [sessionData, setSessionData] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarPinned, setIsSidebarPinned] = useState(false);
  const [messages, setMessages] = useState([]);
  const [graphData, setGraphData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [tokenUsage, setTokenUsage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showRightPanel, setShowRightPanel] = useState(true);
  const [maximizedPane, setMaximizedPane] = useState(null);
  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (location.state?.sessionData) {
      setSessionData(location.state.sessionData);
    }
    // Automatically create a new topic when the component mounts
    handleNewTopic();
  }, [location]);

  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTo({
        top: chatHistoryRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleNewTopic = useCallback(() => {
    const newTopicId = uuidv4();
    setTopicId(newTopicId);
    setMessages([]);
    setGraphData(null);
    setTableData(null);
    setTokenUsage(null);
    setError(null);
  }, []);

  const handleSelectThread = (selectedTopicId, threadMessages) => {
    setTopicId(selectedTopicId);
    const formattedMessages = threadMessages.map(msg => ({
      text: msg.question,
      sender: 'user',
      createdAt: msg.created_at
    })).concat(threadMessages.map(msg => ({
      text: msg.response.summary,
      sender: 'ai',
      createdAt: msg.created_at,
      graphData: msg.response.graph,
      tableData: JSON.parse(msg.response.df)
    }))).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    setMessages(formattedMessages);

    if (threadMessages.length > 0) {
      const lastMessage = threadMessages[threadMessages.length - 1];
      setGraphData(lastMessage.response.graph);
      setTableData(JSON.parse(lastMessage.response.df));
      setTokenUsage({
        query: lastMessage.response['query tokens used'],
        summary: lastMessage.response['summary tokens used'],
        total: lastMessage.response['query tokens used'] + lastMessage.response['summary tokens used']
      });
    }
  };

  const handleSendMessage = async (question) => {
    if (!topicId) {
      setError("Please start a new topic before sending a message.");
      return;
    }
  
    setMessages(prevMessages => [...prevMessages, { text: question, sender: 'user' }]);
    setIsLoading(true);
    setError(null);
  
    try {
      console.log('Sending request to server...');
      const response = await axios.post(`/ask/${agentId}`, {
        session_id: sessionData.session_id,
        thread_id: topicId,
        question: question
      }, {
        params: { user_id: userInfo.id }
      });
  

  
      const aiResponse = response.data.response.summary;

      // // Existing code for typing effect and updating state
      // setIsTyping(true);
      // let i = 0;
      // const typingInterval = setInterval(() => {
      //   if (i < aiResponse.length) {
      //     setTypingText(aiResponse.slice(0, i + 1));
      //     i++;
      //   } else {
      //     clearInterval(typingInterval);
      //     setIsTyping(false);
      //     setMessages(prevMessages => [
      //       ...prevMessages,
      //       { 
      //         text: aiResponse, 
      //         sender: 'ai',
      //         graphData: response.data.response.graph,
      //         tableData: JSON.parse(response.data.response.df)
      //       }
      //     ]);
      //   }
      // }, 10);
      // Remove typing effect and directly update state
    setIsTyping(false);
    setMessages(prevMessages => [
      ...prevMessages,
      {
        text: aiResponse,
        sender: 'ai',
        graphData: response.data.response.graph,
        tableData: JSON.parse(response.data.response.df)
      }
    ]);
  

      setGraphData(response.data.response.graph);
      setTableData(JSON.parse(response.data.response.df));
  
      setTokenUsage({
        query: response.data.response['query tokens used'],
        summary: response.data.response['summary tokens used'],
        total: response.data.response['query tokens used'] + response.data.response['summary tokens used']
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Unable to get response. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleMessageSelect = (message) => {
    if (message.sender === 'ai') {
      setGraphData(message.graphData);
      setTableData(message.tableData);
    }
  };

  const getTableColumns = (data) => {
    if (!data || Object.keys(data).length === 0) return [];
    return Object.keys(data).map(key => ({
      field: key,
      headerName: key,
      flex: 1,
    }));
  };

  const getTableRows = (data) => {
    if (!data || Object.keys(data).length === 0) return [];
    return Object.keys(data[Object.keys(data)[0]]).map(index => ({
      id: index,
      ...Object.keys(data).reduce((acc, key) => {
        acc[key] = data[key][index];
        return acc;
      }, {})
    }));
  };

  const toggleRightPanel = () => {
    setShowRightPanel(!showRightPanel);
  };

  const toggleSidebarPin = () => {
    setIsSidebarPinned(!isSidebarPinned);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    if (!isSidebarPinned) {
      setIsSidebarOpen(false);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const maximizePane = (pane) => {
    setMaximizedPane(pane);
    document.body.style.overflow = 'hidden';
  };

  const closeMaximizedPane = () => {
    setMaximizedPane(null);
    document.body.style.overflow = 'auto';
  };
  const iconStyle = {
    fontSize: '1.0rem',
    color: theme.palette.grey[400],
  };


  const renderMessageActions = (message) => {
    if (message.sender === 'ai') {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <IconButton size="small" onClick={() => handleThumbsUp(message)}>
            <ThumbUpAltOutlinedIcon sx={iconStyle} />
          </IconButton>
          <IconButton size="small" onClick={() => handleThumbsDown(message)}>
            <ThumbDownAltOutlinedIcon sx={iconStyle} />
          </IconButton>
          <IconButton size="small" onClick={() => handleCopyResponse(message)}>
            <ContentCopyIcon sx={iconStyle} />
          </IconButton>
        </Box>
      );
    }
    return null;
  };

  const handleThumbsUp = (message) => {
    // Implement thumbs up logic
    console.log('Thumbs up for message:', message);
  };

  const handleThumbsDown = (message) => {
    // Implement thumbs down logic
    console.log('Thumbs down for message:', message);
  };

  const handleCopyResponse = (message) => {
    navigator.clipboard.writeText(message.text)
      .then(() => {
        // You can show a snackbar or toast here to indicate successful copy
        console.log('Message copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy message: ', err);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        mt: HEADER_HEIGHT,
        maxWidth: '1500px',
        width: '100%',
        mr: 'auto',
        ml: 'auto',
        position: 'relative',
      }}
    >
      <ChatSidebar
        isOpen={isSidebarOpen}
        isPinned={isSidebarPinned}
        agentId={agentId}
        onSelectThread={handleSelectThread}
        onNewTopic={handleNewTopic}
        onTogglePin={toggleSidebarPin}
        onClose={closeSidebar}
        iconStyle={iconStyle}
      />
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexGrow: 1,
          height: '100%',
          overflow: 'hidden',
          borderRadius: 2,
          transition: 'margin-left 0.3s ease-in-out, width 0.3s ease-in-out',
          ml: (isSidebarOpen || isSidebarPinned) ? `${SIDEBAR_WIDTH}px` : 0,
        }}
      >
        <Box
          sx={{
            width: showRightPanel ? `calc(100% - ${RIGHT_PANEL_WIDTH}px)` : '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, minHeight: 0 }} ref={chatHistoryRef}>
            <ChatHistory
              messages={messages}
              onMessageSelect={handleMessageSelect}
              renderMessageActions={renderMessageActions}
              renderMarkdown={(text) => (
                <ReactMarkdown
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          style={tomorrow}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        >
                          {String(children).replace(/\n$/, '')}
                        </SyntaxHighlighter>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    }
                  }}
                >
                  {text}
                </ReactMarkdown>
              )}
            />
            {isTyping && (
              <Box sx={{ mt: 2 }}>
                <Paper
                  elevation={1}
                  sx={{
                    maxWidth: '70%',
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'grey.100',
                  }}
                >
                  <ReactMarkdown
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                          <SyntaxHighlighter
                            style={tomorrow}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {String(children).replace(/\n$/, '')}
                          </SyntaxHighlighter>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        )
                      }
                    }}
                  >
                    {typingText}
                  </ReactMarkdown>
                </Paper>
              </Box>
            )}
            {isLoading && <ProgressingMessage />}
          </Box>
          <ChatInput onSendMessage={handleSendMessage} />
        </Box>
        {showRightPanel && (
          <Box
            sx={{
              width: RIGHT_PANEL_WIDTH,
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              borderLeft: '1px solid',
              borderColor: 'divider',
              minHeight: 0,
            }}
          >
            <Box
              sx={{
                height: '50%',
                borderBottom: '1px solid',
                borderColor: 'divider',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>Graph</Typography>
                <IconButton onClick={() => maximizePane('graph')} size="small">
                  <OpenInFullIcon sx={iconStyle} />
                </IconButton>
              </Box>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 0 }}>
                {graphData ? (
                  <Graph data={graphData} isMaximized={false} />
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No graph available
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                height: '50%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>Data</Typography>
                <IconButton onClick={() => maximizePane('table')} size="small">
                  <OpenInFullIcon sx={iconStyle} />
                </IconButton>
              </Box>
              <Box sx={{ flexGrow: 1, overflow: 'auto', minHeight: 0 }}>
                {tableData ? (
                  <DataGrid
                    rows={getTableRows(tableData)}
                    columns={getTableColumns(tableData)}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    autoHeight
                    sx={{
                      '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        borderBottom: 'none',
                      },
                    }}
                  />
                ) : (
                  <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                      No data available
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
      {!isSidebarOpen && (
        <IconButton
          onClick={toggleSidebar}
          sx={{
            position: 'fixed',
            top: `calc(${HEADER_HEIGHT} + 16px)`,
            left: '16px',
            zIndex: 1000,
          }}
          size="small"
        >
          <MenuIcon sx={iconStyle} />
        </IconButton>
      )}
      <IconButton
        onClick={toggleRightPanel}
        sx={{
          position: 'fixed',
          top: `calc(${HEADER_HEIGHT} + 16px)`,
          right: '16px',
          zIndex: 1000,
        }}
        size="small"
      >
        {showRightPanel ? <VisibilityOffIcon sx={iconStyle} /> : <VisibilityIcon sx={iconStyle} />}
      </IconButton>
      {/* Maximized Views */}
      {maximizedPane && (
        <Box
          sx={{
            position: 'fixed',
            top: HEADER_HEIGHT,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: 'background.paper',
            zIndex: 1300,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">{maximizedPane === 'graph' ? 'Graph' : 'Data'}</Typography>
            <IconButton onClick={closeMaximizedPane}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {maximizedPane === 'graph' && graphData && (
              <Graph data={graphData} isMaximized={true} />
            )}
            {maximizedPane === 'table' && tableData && (
              <DataGrid
                rows={getTableRows(tableData)}
                columns={getTableColumns(tableData)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                autoHeight
                sx={{
                  '& .MuiDataGrid-cell': {
                    borderBottom: 'none',
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderBottom: 'none',
                  },
                }}
              />
            )}
          </Box>
        </Box>
      )}

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChatInterface;