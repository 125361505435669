import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const UserConfiguration = ({ user, onUpdate, onDelete, companies }) => {
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    company_id: '',
  });

  useEffect(() => {
    if (user) {
      setUserData({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        company_id: user.company_id || '',
      });
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onUpdate(userData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        User Configuration
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="firstname"
        label="First Name"
        value={userData.firstname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="lastname"
        label="Last Name"
        value={userData.lastname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="email"
        label="Email"
        type="email"
        value={userData.email}
        onChange={handleChange}
        required
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="company-select-label">Company</InputLabel>
        <Select
          labelId="company-select-label"
          name="company_id"
          value={userData.company_id}
          onChange={handleChange}
          required
        >
          {companies.map((company) => (
            <MenuItem key={company.id} value={company.id}>
              {company.companyname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onDelete} variant="outlined" color="error">
          Delete User
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default UserConfiguration;