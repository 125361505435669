import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Sidebar from './CommonComponents/Sidebar';
import Header from './CommonComponents/Header';
import AgentList from './AgentComponents/AgentConfiguration/AgentList';
import AuthProvider, { useAuth } from './AuthenticationComponents/AuthProvider';
import NewAgent from './AgentComponents/AgentConfiguration/NewAgent';
import EditAgent from './AgentComponents/AgentConfiguration/EditAgent';
import TrainingForm from './AgentComponents/AgentTraining/TrainingForm';
import { LoadingProvider } from './AgentComponents/Common/LoadingContext';
import LoadingSpinner from './CommonComponents/LoadingSpinner';
import ProtectedRoute from './AuthenticationComponents/ProtectedRoute';
import CompanyAdministration from './CompanyComponents/CompanyAdministration';
import UserAdministration from './UserComponents/UserAdministration';
import AgentLandingPage from './AgentComponents/AgentConfiguration/AgentLandingPage';
import Login from './AuthenticationComponents/Login';
import axios from './CommonComponents/axiosConfig'; 
import LandingPage from './Components/LandingPage';
import ChatInterface from './ChatComponent/ChatInterface';

axios.defaults.withCredentials = true;
const drawerWidth = 280;
const theme = createTheme({
  palette: {
    primary: {
      main: '#3a1c71',
      light: '#d76d77',
      dark: '#2c2c2c',
    },
    secondary: {
      main: '#ffaf7b',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif", // Set Inter as the default font
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          },
        },
      },
    },
  },
});

const AppContent = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Navigate to="/home" replace /> : <LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/home/*"
        element={
          <ProtectedRoute>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
              <Header toggleSidebar={toggleSidebar} />
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} onLogout={handleLogout} />
             <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  mt: '64px',
                  maxWidth: '1200px',
                  width: '100%',
     
               
                }}
              >
                <Routes>
                  <Route index element={<AgentLandingPage />} />
                  <Route path="agents" element={<AgentList />} />
                  <Route path="agents/new" element={<NewAgent />} />
                  <Route path="agents/:agentId/edit" element={<EditAgent />} />
                  <Route path="agents/:agentId/training" element={<TrainingForm />} />
                  <Route path="company-admin" element={<CompanyAdministration />} />
                  <Route path="user-admin" element={<UserAdministration />} />
                </Routes>
              </Box>
            </Box>
          </ProtectedRoute>
        }
      />
     <Route
        path="/chat/:agentId"
        element={
          <ProtectedRoute>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
              <Header />
              <ChatInterface />
            </Box>
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingProvider>
        <Router>
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </Router>
        <LoadingSpinner />
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;