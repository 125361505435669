import React, { useState, useCallback } from 'react';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Grid } from '@mui/material';
import useTraining from '../Hooks/useTraining';
import ConfirmationDialog from '../Common/ConfirmationDialog';

const CollectionDetails = ({ agentId }) => {
  const [sqlCollectionInfo, setSqlCollectionInfo] = useState(null);
  const [ddlCollectionInfo, setDdlCollectionInfo] = useState(null);
  const { 
    entries,
    handleFetchData, 
    showConfirmation, 
    confirmationMessage, 
    confirmationAction, 
    isInfoDialog, 
    setShowConfirmation 
  } = useTraining(agentId, 'SQL');

  const fetchCollectionInfo = useCallback(async (collectionParam) => {
    console.log(`Fetching ${collectionParam} collection info`);
    try {
      const fetchedData = await handleFetchData({ collection_param: collectionParam });
      console.log(`Received ${collectionParam} info:`, fetchedData);
      return fetchedData;
    } catch (error) {
      console.error(`Error fetching ${collectionParam} info:`, error);
      return null;
    }
  }, [handleFetchData]);

  const handleFetchAllData = async () => {
    console.log('Fetching all data');
    const sqlInfo = await fetchCollectionInfo('SQL');
    const ddlInfo = await fetchCollectionInfo('DDL');
    console.log('Setting state with fetched data - SQL:', sqlInfo, 'DDL:', ddlInfo);
    setSqlCollectionInfo(sqlInfo);
    setDdlCollectionInfo(ddlInfo);
  };

  const renderTable = (collectionInfo) => {
    console.log('Rendering table with data:', collectionInfo);
    if (!collectionInfo || collectionInfo.length === 0) return <Typography>No data available</Typography>;
    
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {collectionInfo.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{JSON.stringify(item)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  console.log('Current state - SQL:', sqlCollectionInfo, 'DDL:', ddlCollectionInfo);

  return (
    <Box sx={{ p: 3 }}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleFetchAllData} 
        sx={{ mb: 3 }}
      >
        Get Collection Information
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>SQL Collection Info</Typography>
          {renderTable(sqlCollectionInfo)}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>DDL Collection Info</Typography>
          {renderTable(ddlCollectionInfo)}
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={showConfirmation}
        message={confirmationMessage}
        onConfirm={() => {
          confirmationAction?.();
          setShowConfirmation(false);
        }}
        onCancel={() => setShowConfirmation(false)}
        isInfo={isInfoDialog}
      />
    </Box>
  );
};

export default CollectionDetails;