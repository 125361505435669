import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, List, ListItem, ListItemText, IconButton, Grid, Card, CardContent, CardActions } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import useTraining from '../Hooks/useTraining';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { generateSQLMemory } from '../Utils/api';

const GenerateMemory = ({ agentId }) => {
  const [tableNames, setTableNames] = useState([]);
  const [currentTableName, setCurrentTableName] = useState('');
  const [editMode, setEditMode] = useState({});
  const [editText, setEditText] = useState({});

  const { 
    entries: results,
    showConfirmation,
    confirmationMessage,
    confirmationAction,
    isInfoDialog,
    handleAddEntry,
    handleRemoveEntry,
    handleRemoveAllEntries,
    handlePublish,
    setShowConfirmation,
    setConfirmationMessage,
    setConfirmationAction,
    setIsInfoDialog
  } = useTraining(agentId, 'SQL');

  const handleAddTableName = () => {
    if (currentTableName && !tableNames.includes(currentTableName)) {
      setTableNames([...tableNames, currentTableName]);
      setCurrentTableName('');
    }
  };

  const handleRemoveTableName = (tableName) => {
    setTableNames(tableNames.filter(name => name !== tableName));
  };

  const handleGenerate = async () => {
    try {
      const generatedData = await generateSQLMemory(agentId, tableNames);
      generatedData.forEach((item, index) => {
        handleAddEntry({ [`Query ${index + 1}`]: item });
      });
    } catch (error) {
      console.error('Error generating SQL memory:', error);
      setConfirmationMessage('Error generating SQL memory. Please try again.');
      setIsInfoDialog(true);
      setShowConfirmation(true);
    }
  };

  const handleEdit = (tableName) => {
    setEditMode({ ...editMode, [tableName]: true });
    setEditText({ ...editText, [tableName]: JSON.stringify(results[tableName], null, 2) });
  };

  const handleSave = (tableName) => {
    try {
      const updatedEntry = { [tableName]: JSON.parse(editText[tableName]) };
      handleRemoveEntry(tableName);
      handleAddEntry(updatedEntry);
      setEditMode({ ...editMode, [tableName]: false });
    } catch (error) {
      console.error("Invalid JSON format:", error);
      setConfirmationMessage("Invalid JSON format. Please correct the JSON and try again.");
      setIsInfoDialog(true);
      setShowConfirmation(true);
    }
  };

  const handleCancel = (tableName) => {
    setEditMode({ ...editMode, [tableName]: false });
  };

  const handlePublishClick = () => {
    setConfirmationMessage("Do you want to publish this data set?");
    setConfirmationAction(() => handlePublish);
    setIsInfoDialog(false);
    setShowConfirmation(true);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, p: 2 }}>
      <Paper elevation={3} sx={{ flex: 1, p: 2, color: '#6c7581' }}>
        <Typography variant="h6" gutterBottom>Add Tables</Typography>
        <TextField
          fullWidth
          label="Table Name"
          value={currentTableName}
          onChange={(e) => setCurrentTableName(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddTableName}
          startIcon={<AddIcon />}
          sx={{ mt: 1, color: 'white' }}
        >
          Add Table
        </Button>
        <List sx={{ mt: 2 }}>
          {tableNames.map((name, index) => (
            <ListItem key={index} secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveTableName(name)}>
                <DeleteIcon />
              </IconButton>
            }>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerate}
          disabled={tableNames.length === 0}
          sx={{ mt: 2, color: 'white' }}
        >
          Auto Generate
        </Button>
      </Paper>

      <Paper elevation={3} sx={{ flex: 2, p: 2, maxHeight: 'calc(100vh - 100px)', overflowY: 'auto', color: '#6c7581' }}>
        {Object.keys(results).length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Button variant="outlined" color="secondary" onClick={handleRemoveAllEntries} sx={{ mr: 1, color: '#6c7581', borderColor: '#6c7581' }}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={handlePublishClick} sx={{ mr: 1, color: 'white' }}>
              Publish
            </Button>
          </Box>
        )}
        <Grid container spacing={2}>
          {Object.entries(results).map(([tableName, result], index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Table: {tableName}</Typography>
                  {!editMode[tableName] ? (
                    <pre>{JSON.stringify(result, null, 2)}</pre>
                  ) : (
                    <TextField
                      fullWidth
                      multiline
                      rows={10}
                      value={editText[tableName]}
                      onChange={(e) => setEditText({ ...editText, [tableName]: e.target.value })}
                      variant="outlined"
                    />
                  )}
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleRemoveEntry(tableName)}>
                    <DeleteIcon />
                  </IconButton>
                  {!editMode[tableName] ? (
                    <IconButton onClick={() => handleEdit(tableName)}>
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton onClick={() => handleSave(tableName)}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton onClick={() => handleCancel(tableName)}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {Object.keys(results).length === 0 && (
          <Typography variant="body1">No Records to Display</Typography>
        )}
      </Paper>
      
      <ConfirmationDialog
        open={showConfirmation}
        message={confirmationMessage}
        onConfirm={() => {
          confirmationAction?.();
          setShowConfirmation(false);
        }}
        onCancel={() => setShowConfirmation(false)}
        isInfo={isInfoDialog}
      />
    </Box>
  );
};

export default GenerateMemory;