import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Tab, Button, useTheme, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import axios from '../CommonComponents/axiosConfig';
import UserList from './UserList';
import NewUserForm from './NewUserForm';
import UserConfiguration from './UserConfiguration';
import UserAgents from './UserAgents';
import { useLoading } from  '../AgentComponents/Common/LoadingContext'

const UserAdministration = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isNewUserFormOpen, setIsNewUserFormOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [companies, setCompanies] = useState([]);
  const { startLoading, stopLoading } = useLoading();
  const theme = useTheme();

  useEffect(() => {
    fetchUsers();
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`/api/companies?skip=0&limit=100`);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      showSnackbar('Failed to fetch companies', 'error');
    }
  };

  const fetchUsers = async () => {
    startLoading();
    try {
      const response = await axios.get(`/api/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Failed to fetch users', 'error');
    }finally{ 
      stopLoading();
    }
  };

  const handleNewUser = () => {
    setSelectedUser(null);
    setIsNewUserFormOpen(true);
    setActiveTab(0);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setIsNewUserFormOpen(false);
    setActiveTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCreateUser = async (userData) => {
    startLoading();
    try {
      await axios.post(`/api/users`, userData);
      fetchUsers();
      setIsNewUserFormOpen(false);
      showSnackbar('User created successfully', 'success');
    } catch (error) {
      console.error('Error creating user:', error);
      showSnackbar('Failed to create user', 'error');
      }finally{ 
      stopLoading();
    }
  };

  const handleUpdateUser = async (userId, userData) => {
    startLoading();
    try {
      await axios.put(`/api/users/${userId}`, userData);
      fetchUsers();
      showSnackbar('User updated successfully', 'success');
    } catch (error) {
      console.error('Error updating user:', error);
      showSnackbar('Failed to update user', 'error');
    }finally{ 
      stopLoading();
    }
  };

  const handleDeleteUser = async (userId) => {
    startLoading();
    try {
      await axios.delete(`/api/users/${userId}`);
      fetchUsers();
      setSelectedUser(null);
      showSnackbar('User deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting user:', error);
      showSnackbar('Failed to delete user', 'error');
        }finally{ 
      stopLoading();
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <h2>Users</h2>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleNewUser}
            >
              New User
            </Button>
          </Box>
          <UserList
            users={users}
            onSelectUser={handleUserSelect}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isNewUserFormOpen ? (
            <NewUserForm
              onClose={() => setIsNewUserFormOpen(false)}
              onSubmit={handleCreateUser}
              companies={companies}
            />
          ) : selectedUser ? (
            <Box>
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                sx={{
                  '& .MuiTab-root': { color: theme.palette.primary.main },
                  '& .Mui-selected': { color: theme.palette.secondary.main },
                  '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main },
                }}
              >
                <Tab label="Configuration" />
                <Tab label="Agents" />
              </Tabs>
              {activeTab === 0 && (
                <UserConfiguration 
                  user={selectedUser}
                  onUpdate={(updatedData) => handleUpdateUser(selectedUser.id, updatedData)}
                  onDelete={() => handleDeleteUser(selectedUser.id)}
                  companies={companies}
                />
              )}
              {activeTab === 1 && (
                <UserAgents user={selectedUser} />
              )}
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default UserAdministration;