import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';



const CompanyList = ({ companies, onSelectCompany }) => {
  return (
    <Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Short Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>User Limit</TableCell>
              <TableCell>Agent Limit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow 
                key={company.id} 
                onClick={() => onSelectCompany(company)} 
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{company.shortname}</TableCell>
                <TableCell>{company.companyname}</TableCell>
                <TableCell>{company.status ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{company.users_limit}</TableCell>
                <TableCell>{company.agents_limit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CompanyList;