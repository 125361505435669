import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../CommonComponents/axiosConfig';
import { useLoading } from  '../AgentComponents/Common/LoadingContext'



const UserAgents = ({ user }) => {
  const [agents, setAgents] = useState([]);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  useEffect(() => {
    fetchUserAgents();
    fetchAvailableAgents();
  }, [user]);

  const fetchUserAgents = async () => {
    startLoading();
    try {
      const response = await axios.get(`/api/users/${user.id}/agents`);
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching user agents:', error);
    }finally{ 
      stopLoading();
    }
  };

  const fetchAvailableAgents = async () => {
    startLoading();
    try {
      const response = await axios.get(`/api/companies/${user.company_id}/agents`);
      setAvailableAgents(response.data);
    } catch (error) {
      console.error('Error fetching company agents:', error);
    }finally{ 
      stopLoading();
    }
  };

  const handleAddAgent = async (agentId) => {
    startLoading();
    try {
      await axios.post(`/api/users/${user.id}/agents/${agentId}`);
      fetchUserAgents();
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error adding agent to user:', error);
    }finally{ 
      stopLoading();
    }
  };

  const handleRemoveAgent = async (agentId) => {
    startLoading();
    try {
      await axios.delete(`/api/users/${user.id}/agents/${agentId}`);
      fetchUserAgents();
    } catch (error) {
      console.error('Error removing agent from user:', error);
        }finally{ 
      stopLoading();
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        User Agents
      </Typography>
      <List>
        {agents.map((agent) => (
          <ListItem key={agent.id}>
            <ListItemText primary={agent.name} />
            <Button onClick={() => handleRemoveAgent(agent.id)} color="error">
              Remove
            </Button>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ mt: 2 }}
      >
        Add Agent
      </Button>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add Agent to User</DialogTitle>
        <DialogContent>
          <List>
            {availableAgents.map((agent) => (
              <ListItem 
                button 
                key={agent.id} 
                onClick={() => handleAddAgent(agent.id)}
              >
                <ListItemText primary={agent.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserAgents;