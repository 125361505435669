import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, MenuItem } from '@mui/material';

const CompanyConfiguration = ({ company, onUpdate, onDelete }) => {
  const [companyData, setCompanyData] = useState({
    shortname: '',
    companyname: '',
    status: true,
    users_limit: 0,
    agents_limit: 0,
  });

  useEffect(() => {
    if (company) {
      setCompanyData({
        shortname: company.shortname,
        companyname: company.companyname,
        status: company.status,
        users_limit: company.users_limit,
        agents_limit: company.agents_limit,
      });
    }
  }, [company]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: name === 'status' ? value === 'true' : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onUpdate(companyData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Company Configuration
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="shortname"
        label="Company Short Name"
        value={companyData.shortname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="companyname"
        label="Company Name"
        value={companyData.companyname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="status"
        label="Status"
        select
        value={companyData.status.toString()}
        onChange={handleChange}
        required
      >
        <MenuItem value="true">Active</MenuItem>
        <MenuItem value="false">Inactive</MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        name="users_limit"
        label="User Limit"
        type="number"
        value={companyData.users_limit}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="agents_limit"
        label="Agent Limit"
        type="number"
        value={companyData.agents_limit}
        onChange={handleChange}
        required
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onDelete} variant="outlined" color="error">
          Delete Company
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyConfiguration;