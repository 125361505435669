import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const ConfirmationDialog = ({ open, message, onConfirm, onCancel, type }) => {
  // Ensure message is always a string
  const dialogMessage = typeof message === 'string' ? message : JSON.stringify(message);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{type === 'confirm' ? 'Confirmation' : type === 'success' ? 'Success' : 'Error'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {type === 'confirm' ? (
          <>
            <Button onClick={onCancel} color="primary">Cancel</Button>
            <Button onClick={onConfirm} color="primary" autoFocus>Confirm</Button>
          </>
        ) : (
          <Button onClick={onConfirm} color="primary" autoFocus>OK</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;