import React, { useState } from 'react';
import { Box, TextField, Button, Typography, MenuItem } from '@mui/material';

const NewCompanyForm = ({ onClose, onSubmit }) => {
  const [companyData, setCompanyData] = useState({
    shortname: '',
    companyname: '',
    status: true,
    users_limit: 0,
    agents_limit: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCompanyData((prevData) => ({
      ...prevData,
      [name]: name === 'status' ? value === 'true' : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(companyData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        New Company
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="shortname"
        label="Company Short Name"
        value={companyData.shortname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="companyname"
        label="Company Name"
        value={companyData.companyname}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="status"
        label="Status"
        select
        value={companyData.status.toString()}
        onChange={handleChange}
        required
      >
        <MenuItem value="true">Active</MenuItem>
        <MenuItem value="false">Inactive</MenuItem>
      </TextField>
      <TextField
        fullWidth
        margin="normal"
        name="users_limit"
        label="User Limit"
        type="number"
        value={companyData.users_limit}
        onChange={handleChange}
        required
      />
      <TextField
        fullWidth
        margin="normal"
        name="agents_limit"
        label="Agent Limit"
        type="number"
        value={companyData.agents_limit}
        onChange={handleChange}
        required
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onClose} variant="outlined" color="primary" sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default NewCompanyForm;