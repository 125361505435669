import React, { useState, useEffect, useCallback } from 'react';
import { 
  Drawer, List, ListItem, ListItemText, Typography, Box, Button, Divider, IconButton, Menu, MenuItem,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PushPinIcon from '@mui/icons-material/PushPin';
import CloseIcon from '@mui/icons-material/Close';
import axios from '../CommonComponents/axiosConfig';
import { useAuth } from '../AuthenticationComponents/AuthProvider';
import { useLoading } from '../AgentComponents/Common/LoadingContext';

const SIDEBAR_WIDTH = 320;

const ChatSidebar = ({ 
  isOpen, 
  isPinned, 
  agentId, 
  onSelectThread, 
  onNewTopic, 
  onTogglePin, 
  onClose,
  iconStyle
}) => {
  const [chatHistory, setChatHistory] = useState([]);
  const { userInfo } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const { startLoading, stopLoading } = useLoading();

  const fetchChatHistory = useCallback(async () => {
    startLoading();
    try {
      const response = await axios.get(`/threads`, {
        params: {
          agent_id: agentId,
          user_id: userInfo.id
        }
      });
      setChatHistory(response.data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      stopLoading();
    }
  }, [agentId, userInfo.id, startLoading, stopLoading]);

  useEffect(() => {
    if (isOpen || isPinned) {
      fetchChatHistory();
    }
  }, [isOpen, isPinned, fetchChatHistory]);

  const handleNewTopic = () => {
    onNewTopic();
  };

  const handleSelectThread = async (threadId) => {
    try {
      startLoading();
      const response = await axios.get(`/threads/${threadId}/messages`, {
        params: { user_id: userInfo.id }
      });
      onSelectThread(threadId, response.data);
      if (!isPinned) {
        onClose();
      }
    } catch (error) {
      console.error('Error fetching thread messages:', error);
    } finally {
      stopLoading();
    }
  };

  const handleMenuOpen = (event, chat) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedChat(chat);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openRenameDialog = () => {
    setNewTitle(selectedChat.title);
    setIsRenameDialogOpen(true);
    handleMenuClose();
  };

  const closeRenameDialog = () => {
    setIsRenameDialogOpen(false);
  };

  const handleRename = async () => {
    if (!selectedChat) return;

    try {
      startLoading();
      await axios.put(`/threads/${selectedChat.topic_id}/rename`, 
        { new_title: newTitle },
        { params: { user_id: userInfo.id } }
      );
      setChatHistory(prevHistory => 
        prevHistory.map(chat => 
          chat.topic_id === selectedChat.topic_id ? { ...chat, title: newTitle } : chat
        )
      );
      closeRenameDialog();
    } catch (error) {
      console.error('Error renaming thread:', error);
    } finally {
      stopLoading();
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
    handleMenuClose();
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    if (!selectedChat) return;

    try {
      startLoading();
      await axios.delete(`/threads/${selectedChat.topic_id}`, {
        params: { user_id: userInfo.id }
      });
      setChatHistory(prevHistory => 
        prevHistory.filter(chat => chat.topic_id !== selectedChat.topic_id)
      );
      closeDeleteDialog();
    } catch (error) {
      console.error('Error deleting thread:', error);
    } finally {
      stopLoading();
    }
  };

  return (
    <Drawer
      variant={isPinned ? "permanent" : "temporary"}
      anchor="left"
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: SIDEBAR_WIDTH,
          boxSizing: 'border-box',
          top: '64px',
          height: 'calc(100% - 64px)',
          backgroundColor: 'rgba(245, 245, 245, 0.95)',
          backdropFilter: 'blur(5px)',
        },
      }}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Chat History</Typography>
          <Box>
            <IconButton onClick={onTogglePin} size="small">
              <PushPinIcon sx={iconStyle} color={isPinned ? "primary" : "action"} />
            </IconButton>
            {!isPinned && (
              <IconButton onClick={onClose} size="small">
                <CloseIcon sx={iconStyle} />
              </IconButton>
            )}
          </Box>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onNewTopic}
          sx={{ mb: 2 }}
        >
          New Topic
        </Button>
        <Divider sx={{ mb: 2 }} />
        <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {chatHistory.map((chat) => (
            <ListItem 
              key={chat.topic_id}
              sx={{
                mb: 1,
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                cursor: 'pointer',
              }}
              onClick={() => handleSelectThread(chat.topic_id)}
            >
              <ListItemText 
                primary={chat.title} 
                secondary={new Date(chat.created_at).toLocaleString()}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />
              <IconButton 
                edge="end" 
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuOpen(e, chat);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={openRenameDialog}>Rename</MenuItem>
        <MenuItem onClick={openDeleteDialog}>Delete</MenuItem>
      </Menu>

      <Dialog open={isRenameDialogOpen} onClose={closeRenameDialog}>
        <DialogTitle>Rename Chat</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Title"
            type="text"
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRenameDialog}>Cancel</Button>
          <Button onClick={handleRename}>Rename</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Delete Chat</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this chat? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default React.memo(ChatSidebar);