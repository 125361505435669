import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const ProgressingMessage = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', p: 2, cursor: 'default' }}>
    <CircularProgress size={20} sx={{ mr: 1 }} />
    <Typography variant="body2">Processing...</Typography>
  </Box>
);

export default ProgressingMessage;