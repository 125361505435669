import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../CommonComponents/axiosConfig';
import { useLoading } from '../Common/LoadingContext';
import { loadConfig } from '../../config';
import { fetchAgents, deleteAgent } from '../Utils/agentapi';
import { useAuth } from '../../AuthenticationComponents/AuthProvider';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, IconButton, Typography, Box, ThemeProvider, createTheme,
  useMediaQuery
} from '@mui/material';
import { Edit, Delete, School } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    primary: {
      main: '#64a7d0',
    },
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          fontSize: '0.875rem',
        },
        head: {
          fontWeight: 600,
          backgroundColor: '#f7f7f7',
          color: '#555555',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #e0e0e0',
        },
      },
    },
  },
});
const StyledIconButton = styled(IconButton)({
    fontSize: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.0,
    color: '#64a7d0',
    transition: 'color 0.3s, transform 0.3s',
    strokeWidth: '8.5',
    height: '36px',  // Increased height for better icon fit
    width: '36px',   // Increased width for better icon fit
    margin: '0 8px',  // Maintain gaps between icons
    
    // Add border around the button
    border: '1px solid #64a7d0',  // Border color and width
    borderRadius: '4px',  // Rounded corners for the border
    padding: '8px',  // Add padding inside the button for better spacing
    
    '& svg': {
      margin: '0',  // Remove margin from the icons
    },
  
    '&:hover': {
      color: '#5f5f5f',
      borderColor: '#5f5f5f',  // Optional: Change border color on hover
      transform: 'scale(1.05)',  // Slightly scale on hover
    },
  });

  const AgentList = () => {
    const { userInfo } = useAuth();
    const [agents, setAgents] = useState([]);
    const { startLoading, stopLoading } = useLoading();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchAgentList();
    }, [userInfo]);

    const fetchAgentList = async () => {
        startLoading();
        try {
            const data = await fetchAgents();
            setAgents(data);
        } catch (error) {
            console.error('Error fetching agents:', error);
            // You might want to set an error state here and display it to the user
        } finally {
            stopLoading();
        }
    };

    const handleDelete = async (agentId) => {
        if (window.confirm("Are you sure you want to delete this agent?")) {
            startLoading();
            try {
                await deleteAgent(agentId, userInfo.id);
                setAgents(agents.filter(agent => agent.agentId !== agentId));
            } catch (error) {
                console.error('Error deleting agent:', error);
                // You might want to show an error message to the user here
            } finally {
                stopLoading();
            }
        }
    };


    return (

            <Box sx={{ p: { xs: 2, sm: 3 }, backgroundColor: theme.palette.background.default }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Typography variant="h5" sx={{ fontWeight: 500, color: theme.palette.text.primary }}>
                        Agent List
                    </Typography>
                    <Button 
                        component={Link} 
                        to="/home/agents/new" 
                        variant="contained" 
                    >
                        Create New Agent
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Agent Code</TableCell>
                                <TableCell>Agent Name</TableCell>
                                <TableCell>Environment</TableCell>
                                <TableCell>Model</TableCell>
                                {!isMobile && <TableCell>Connected to Database</TableCell>}
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agents.map(agent => (
                                <TableRow key={agent.agentId} hover>
                                    <TableCell>{agent.agentCode}</TableCell>
                                    <TableCell>{agent.agentName}</TableCell>
                                    <TableCell>
                                        <Box sx={{
                                            display: 'inline-block',
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: '4px',
                                            fontSize: '0.75rem',
                                            fontWeight: 'medium',
                                            backgroundColor: agent.agentEnvironment === 'Prod' ? '#e8f5e9' : '#e3f2fd',
                                            color: agent.agentEnvironment === 'Prod' ? '#2e7d32' : '#1565c0',
                                        }}>
                                            {agent.agentEnvironment}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{agent.model}</TableCell>
                                    {!isMobile && <TableCell>{agent.databaseInfo.server}</TableCell>}
                                    <TableCell align="right">
                                    <Tooltip title="Edit Agent">
                                        <Button  component={Link} to={`/home/agents/${agent.agentId}/edit`} size="small" color="primary">
                                            <Edit fontSize="small" />
                                        </Button ></Tooltip>
                                        <Tooltip title="Delete Agent">
                                        <Button  onClick={() => handleDelete(agent.agentId)} size="small" color="error">
                                            
                                            <Delete fontSize="small" />
                                        </Button > </Tooltip>
                                        <Tooltip title="Training">
                                        <Button  component={Link} to={`/home/agents/${agent.agentId}/training`} size="small" color="secondary">
                                            <School fontSize="small" />
                                        </Button >
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

    );
};

export default AgentList;