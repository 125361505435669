import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  Icon,
  Avatar,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import SecurityIcon from '@mui/icons-material/Security';
import DataVisualizationIcon from '@mui/icons-material/BarChart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BusinessIcon from '@mui/icons-material/Business';
import SpeedIcon from '@mui/icons-material/Speed';
import ChatIcon from '@mui/icons-material/Chat';
import CodeIcon from '@mui/icons-material/Code';
import InsightsIcon from '@mui/icons-material/Insights';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import dataInsightsImage from '../assets/images/data-insights.jpeg';
import howItWorksImage from '../assets/images/how-it-works.jpg';
import logoBlue from '../assets/logo/QB-Logo-Blue.png';
import logoWhite from '../assets/logo/QB-Logo-White.png';
import MenuIcon from '@mui/icons-material/Menu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  transition: 'all 0.3s ease-in-out',
}));

const StyledToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)',
  },
}));

const LoginButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    transform: 'translateY(-2px)',
  },
}));

const HeroSection = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  color: theme.palette.common.white,
  overflow: 'hidden',
  padding: theme.spacing(8, 0),
}));

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(15, 0),
}));

const FeatureCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const TestimonialCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const PricingCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  border: 0,
  color: 'white',
  padding: '12px 30px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 6px 15px 4px rgba(255, 105, 135, .4)',
  },
}));

const UseCaseCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 30px rgba(0,0,0,0.15)',
  },
}));

const NavBar = React.memo(() => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleScroll = useCallback(() => {
    const isScrolled = window.scrollY > 10;
    if (isScrolled !== scrolled) {
      setScrolled(isScrolled);
    }
  }, [scrolled]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    { text: 'Features', href: '#features' },
    { text: 'Use Cases', href: '#use-cases' },
    { text: 'Testimonials', href: '#testimonials' },
    { text: 'Contact', href: '#contact' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        QueryBud
      </Typography>
      <MuiList>
        {menuItems.map((item) => (
          <MuiListItem key={item.text} disablePadding>
            <MuiListItemText>
              <NavButton href={item.href} sx={{ color: 'text.primary', display: 'block' }}>
                {item.text}
              </NavButton>
            </MuiListItemText>
          </MuiListItem>
        ))}
        <MuiListItem disablePadding>
          <MuiListItemText>
            <LoginButton component={RouterLink} to="/login" variant="contained" fullWidth>
              Login
            </LoginButton>
          </MuiListItemText>
        </MuiListItem>
      </MuiList>
    </Box>
  );

  return (
    <StyledAppBar position="fixed" style={{
      backgroundColor: scrolled ? 'rgba(255, 255, 255, 0.95)' : 'transparent',
      boxShadow: scrolled ? '0 2px 10px rgba(0,0,0,0.1)' : 'none',
    }}>
      <StyledToolbar sx={{ height: { xs: 64, sm: 80 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={scrolled ? logoBlue : logoWhite}
            alt="QueryBud Logo"
            style={{ 
              height: '40px', 
              marginRight: '10px',
              transition: 'opacity 0.3s ease-in-out',
            }}
          />
          <Typography variant="h4" component="div" sx={{ 
            fontWeight: 'bold', 
            color: scrolled ? 'primary.main' : 'white',
            transition: 'color 0.3s ease-in-out',
          }}>
            QueryBud
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          {menuItems.map((item) => (
            <NavButton 
              key={item.text}
              href={item.href} 
              sx={{ color: scrolled ? 'text.primary' : 'white' }}
            >
              {item.text}
            </NavButton>
          ))}
          <LoginButton 
            component={RouterLink} 
            to="/login" 
            variant="contained"
            sx={{ ml: 2 }} // Add left margin to match other items
          >
            Login
          </LoginButton>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </StyledToolbar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </StyledAppBar>
  );
});
const Feature = React.memo(({ icon, title, description }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <FeatureCard
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        height: 120, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        mb: 2,
        overflow: 'visible' // This ensures the icon is not cut off
      }}>
        <Icon color="primary" sx={{ fontSize: 60, transform: 'scale(1.5)' }}>{icon}</Icon>
      </Box>
      <Typography variant="h5" component="h3" gutterBottom align="center" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography align="center" variant="body2">
        {description}
      </Typography>
    </FeatureCard>
  );
});

const LandingPage = () => {
  const [benefitsRef, benefitsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [featuresRef, featuresInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [howItWorksRef, howItWorksInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialsRef, testimonialsInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [pricingRef, pricingInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ctaRef, ctaInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [contactRef, contactInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [useCasesRef, useCasesInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  const features = [
    { icon: <LanguageIcon fontSize="large" />, title: 'Natural Language Queries', description: 'Ask your data anything, in everyday language.' },
    { icon: <DataVisualizationIcon fontSize="large" />, title: 'Intuitive Interface', description: 'No technical skills required. Just ask and get answers.' },
    { icon: <SecurityIcon fontSize="large" />, title: 'Secure Integration', description: 'Seamlessly integrate with your existing datasources like databases and documents.' },
  ];

  const testimonials = [
    { name: 'Amay Mahajan', company: 'Pious Labs', quote: 'QueryBud has revolutionized how we interact with our data. It\'s like having a data scientist on call 24/7.' },
    { name: 'Pratik Patel', company: 'Viom Care', quote: 'The insights we\'ve gained using QueryBud have directly contributed to a 30% increase in our operational efficiency.' },
  ];

  const useCases = [
    {
      industry: 'Pharmaceutical',
      cases: [
        'Optimize manufacturing processes by quickly querying production data.',
        'Enhance quality control through efficient retrieval of batch testing results.',
        'Analyze clinical trial data, enabling more efficient trial management and decision-making.'
      ]
    },
    {
      industry: 'Hospital Management',
      cases: [
        'Optimize patient care by quickly accessing and analyzing electronic health records.',
        'Improve resource allocation through intuitive querying of hospital operational data.',
        'Enhance clinical decision support with rapid information retrieval from medical literature.'
      ]
    },
    {
      industry: 'Manufacturing',
      cases: [
        'Boost production efficiency by easily querying and analyzing process data.',
        'Improve quality control through quick retrieval of historical defect information.',
        'Optimize supply chain management with natural language queries on inventory and logistics data.'
      ]
    }
  ];

  const initialAnimationState = useMemo(() => ({ opacity: 0, y: 50 }), []);

  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <NavBar />

      <HeroSection>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={isMobile ? { opacity: 1, x: 0 } : { x: '50%', opacity: 0 }}
                animate={isMobile ? { opacity: 1, x: 0 } : { x: showImage ? 0 : '50%', opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                style={isMobile ? { textAlign: 'center' } : {}}
              >
                <Typography variant="h1" component="h1" gutterBottom sx={{ 
                  fontWeight: 'bold', 
                  mb: 3, 
                  fontSize: { xs: '2rem', sm: '2.25rem', md: '3.15rem' },
                  textAlign: { xs: 'center', md: 'left' }
                }}>
                  Engage with your data
                </Typography>
                <Typography variant="h4" component="h2" gutterBottom sx={{ 
                  mb: 4, 
                  fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
                  textAlign: { xs: 'center', md: 'left' }
                }}>
                  Get answers when you need them, delivered instantly with AI.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                  <GradientButton 
                    variant="contained" 
                    size="large" 
                    href="mailto:sales@querybud.com"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Start Your Data Journey
                  </GradientButton>
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={isMobile ? { opacity: 1, x: 0 } : { opacity: 0, x: 100 }}
                animate={isMobile ? { opacity: 1, x: 0 } : { opacity: showImage ? 1 : 0, x: showImage ? 0 : 100 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <Box sx={{ maxWidth: '70%', mx: 'auto' }}>
                  <img
                    src={dataInsightsImage}
                    alt="Data Insights"
                    style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </HeroSection>

      <Section id="benefits" sx={{ bgcolor: 'grey.50' }}>
        <Container>
          <motion.div
            ref={benefitsRef}
            initial={{ opacity: 0, y: 50 }}
            animate={benefitsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold', fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
              How QueryBud Empowers Your Business
            </Typography>
          </motion.div>
          <Grid container spacing={6}>
            {[
              { icon: <BusinessIcon fontSize="large" />, title: 'Make Data Accessible', description: 'Enable everyone in your organization to access and analyze data.' },
              { icon: <SpeedIcon fontSize="large" />, title: 'Accelerate Decision Making', description: 'Get answers to your business questions in seconds, not days or weeks.' },
              { icon: <DataVisualizationIcon fontSize="large" />, title: 'Uncover Hidden Insights', description: 'Discover patterns and trends in your data that drive business growth.' },
            ].map((benefit, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Feature {...benefit} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      <Section id="features">
        <Container>
          <motion.div
            ref={featuresRef}
            initial={{ opacity: 0, y: 50 }}
            animate={featuresInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold', fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
              Powerful Features
            </Typography>
          </motion.div>
          <Grid container spacing={6}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Feature {...feature} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      <Section id="how-it-works" sx={{ bgcolor: 'grey.50' }}>
        <Container>
          <motion.div
            ref={howItWorksRef}
            initial={{ opacity: 0, y: 50 }}
            animate={howItWorksInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold' }}>
              How It Works
            </Typography>
          </motion.div>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.img
                src={howItWorksImage}
                alt="How It Works"
                style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}
                initial={{ opacity: 0, x: -50 }}
                animate={howItWorksInView ? { opacity: 1, x: 0 } : {}}
                transition={{ duration: 0.5, delay: 0.2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {[
                { icon: <ChatIcon />, step: 'Ask Your Question', description: 'Type your business question in everyday language.' },
                { icon: <CodeIcon />, step: 'AI Translation', description: 'Our AI instantly understands your question and retrieves the relevant data.' },
                { icon: <InsightsIcon />, step: 'Get Results', description: 'View your results as intuitive visualizations or raw data.' },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 50 }}
                  animate={howItWorksInView ? { opacity: 1, x: 0 } : {}}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Icon color="primary" sx={{ fontSize: 40, mr: 2 }}>
                      {item.icon}
                    </Icon>
                    <Typography variant="h5" sx={{ fontWeight: 'medium' }}>{`${index + 1}. ${item.step}`}</Typography>
                  </Box>
                  <Typography variant="body1" sx={{ ml: 7, mb: 4 }}>{item.description}</Typography>
                </motion.div>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section id="testimonials">
        <Container>
          <motion.div
            ref={testimonialsRef}
            initial={{ opacity: 0, y: 50 }}
            animate={testimonialsInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold' }}>
              What Our Clients Say
            </Typography>
          </motion.div>
          <Grid container spacing={6}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={6} key={index}>
                <TestimonialCard
                  initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                  animate={testimonialsInView ? { opacity: 1, x: 0 } : {}}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <CardContent>
                    <Box sx={{ display: 'flex', mb: 3 }}>
                      {[...Array(5)].map((_, i) => (
                        <StarIcon key={i} sx={{ color: 'primary.main' }} />
                      ))}
                    </Box>
                    <Typography variant="body1" paragraph sx={{ fontStyle: 'italic', mb: 3 }}>"{testimonial.quote}"</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>{testimonial.name[0]}</Avatar>
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{testimonial.name}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">{testimonial.company}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </TestimonialCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>

      {/* Pricing section hidden */}
      {/* <Section id="pricing" sx={{ bgcolor: 'grey.50', display: 'none' }}> */}
      {/*   <Container> */}
      {/*     <motion.div */}
      {/*       ref={pricingRef}
      {/*       initial={{ opacity: 0, y: 50 }} */}
      {/*       animate={pricingInView ? { opacity: 1, y: 0 } : {}} */}
      {/*       transition={{ duration: 0.5 }} */}
      {/*     > */}
      {/*       <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold' }}> */}
      {/*         Choose Your Plan */}
      {/*       </Typography> */}
      {/*     </motion.div> */}
      {/*     <Grid container spacing={6} justifyContent="center"> */}
      {/*       {[ */}
      {/*         { plan: 'Basic', price: '$49', features: ['5 user accounts', '1000 queries/month', 'Basic analytics', 'Email support'] }, */}
      {/*         { plan: 'Pro', price: '$99', features: ['20 user accounts', '2500 queries/month', 'Advanced analytics', 'Priority support', 'Custom dashboards'] }, */}
      {/*         { plan: 'Enterprise', price: 'Custom', features: ['Unlimited user accounts', 'Unlimited queries', 'Custom integrations', 'Dedicated support', 'On-premise deployment option'] }, */}
      {/*       ].map((pricing, index) => ( */}
      {/*         <Grid item xs={12} md={4} key={index}> */}
      {/*           <PricingCard */}
      {/*             initial={{ opacity: 0, y: 50 }} */}
      {/*             animate={pricingInView ? { opacity: 1, y: 0 } : {}} */}
      {/*             transition={{ duration: 0.5, delay: index * 0.1 }} */}
      {/*           > */}
      {/*             <CardContent> */}
      {/*               <Typography variant="h4" component="h3" gutterBottom align="center" sx={{ fontWeight: 'bold' }}> */}
      {/*                 {pricing.plan} */}
      {/*               </Typography> */}
      {/*               <Typography variant="h3" component="p" gutterBottom align="center" sx={{ color: 'primary.main', my: 4 }}> */}
      {/*                 {pricing.price} */}
      {/*                 {pricing.price !== 'Custom' && <Typography component="span" variant="subtitle1">/mo</Typography>} */}
      {/*               </Typography> */}
      {/*               {pricing.features.map((feature, idx) => ( */}
      {/*                 <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}> */}
      {/*                   <CheckCircleIcon color="primary" sx={{ mr: 1 }} /> */}
      {/*                   <Typography>{feature}</Typography> */}
      {/*                 </Box> */}
      {/*               ))} */}
      {/*             </CardContent> */}
      {/*             <Box sx={{ mt: 4, textAlign: 'center' }}> */}
      {/*               <GradientButton variant="contained"> */}
      {/*                 {pricing.plan === 'Enterprise' ? 'Contact Us' : 'Choose Plan'} */}
      {/*               </GradientButton> */}
      {/*             </Box> */}
      {/*           </PricingCard> */}
      {/*         </Grid> */}
      {/*       ))} */}
      {/*     </Grid> */}
      {/*   </Container> */}
      {/* </Section> */}

      <Section id="cta" sx={{ bgcolor: 'primary.main', color: 'white' }}>
        <Container maxWidth="md">
          <motion.div
            ref={ctaRef}
            initial={{ opacity: 0, y: 50 }}
            animate={ctaInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
              Ready to Transform Your Data Experience?
            </Typography>
            <Typography variant="h6" paragraph align="center" sx={{ mb: 6 }}>
              Join businesses already leveraging the power of QueryBud.
            </Typography>
            {/* Contact Us button removed */}
          </motion.div>
        </Container>
      </Section>

      <Section id="contact">
        <Container maxWidth="md">
          <motion.div
            ref={contactRef}
            initial={{ opacity: 0, y: 50 }}
            animate={contactInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 4, fontWeight: 'bold' }}>
              Get in Touch
            </Typography>
            <Typography variant="h6" paragraph align="center" sx={{ mb: 6 }}>
              Have questions? Our team is here to help. Reach out to us anytime.
            </Typography>
            <Box display="flex" justifyContent="center">
              <GradientButton 
                variant="contained" 
                size="large" 
                endIcon={<EmailIcon />}
                href="mailto:sales@querybud.com"
              >
                Contact Us
              </GradientButton>
            </Box>
          </motion.div>
        </Container>
      </Section>

      <Section id="use-cases" sx={{ bgcolor: 'grey.50' }}>
        <Container>
          <motion.div
            ref={useCasesRef}
            initial={{ opacity: 0, y: 50 }}
            animate={useCasesInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" component="h2" gutterBottom align="center" sx={{ mb: 8, fontWeight: 'bold', fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
              Industry Use Cases
            </Typography>
          </motion.div>
          <Grid container spacing={6}>
            {useCases.map((industryCase, index) => (
              <Grid item xs={12} md={4} key={index}>
                <UseCaseCard
                  initial={{ opacity: 0, y: 50 }}
                  animate={useCasesInView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Box>
                    <Typography variant="h5" component="h3" gutterBottom align="center" sx={{ mb: 3, fontWeight: 'bold' }}>
                      {industryCase.industry}
                    </Typography>
                    <List>
                      {industryCase.cases.map((useCase, idx) => (
                        <ListItem key={idx}>
                          <ListItemIcon>
                            <BusinessCenterIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={useCase} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                  {/* Learn More button hidden */}
                </UseCaseCard>
              </Grid>
            ))}
          </Grid>
          <Box mt={6} textAlign="center">
            <Typography variant="h6" color="text.secondary">
              And many more industries...
            </Typography>
          </Box>
        </Container>
      </Section>

      <Box component="footer" sx={{ bgcolor: 'grey.900', color: 'white', py: 6 }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                QueryBud
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Empowering businesses with AI-driven data insights
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Link href="#features" color="inherit" display="block">Features</Link>
              <Link href="#contact" color="inherit" display="block">Contact</Link>
              <Link href="#" color="inherit" display="block">Terms of Service</Link>
              <Link href="#" color="inherit" display="block">Privacy Policy</Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Connect With Us
              </Typography>
              <Box>
                <IconButton 
                  color="inherit" 
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/company/querybud"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4} pt={4} borderTop={1} borderColor="grey.800">
            <Typography variant="body2" align="center">
              © {new Date().getFullYear()} QueryBud. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;