import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, Select, MenuItem, FormControl, InputLabel, 
  Checkbox, FormControlLabel, Button, Typography, 
  Paper, Box, ThemeProvider, createTheme, CssBaseline,
  Container, Stepper, Step, StepLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    text: {
      primary: '#0D0D0D',
    },
    background: {
      default: '#f4f4f4',
      paper: '#FFFFFF',
    },
    primary: {
      main: '#64a7d0',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ddd',
            },
            '&:hover fieldset': {
              borderColor: '#64a7d0',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ddd',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#64a7d0',
          },
        },
      },
    },
  },
});

const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const FormSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
}));


const AgentForm = ({ initialValues, onSubmit, isEditMode = false }) => {
  const [agentData, setAgentData] = useState(initialValues || {
    agentName: '',
    agentCode: '',
    agentDescription: '',
    agentBusinessContext: '',
    agentEnvironment: 'Test',
    databaseInfo: { server: '', database: '', username: '', password: '' },
    openai_api_key: '',
    model: 'gpt-4',
    maintenance_mode: false
  });
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();



  useEffect(() => {
    if (initialValues) {
      setAgentData(initialValues);
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;
  
    setAgentData((prevData) => {
      const keys = name.split('.');
      if (keys.length === 1) {
        return { ...prevData, [name]: updatedValue };
      }
      const [firstKey, secondKey] = keys;
      return {
        ...prevData,
        [firstKey]: { ...prevData[firstKey], [secondKey]: updatedValue },
      };
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!agentData.agentName) newErrors.agentName = 'Agent Name is required';
    if (!agentData.agentCode) newErrors.agentCode = 'Agent Code is required';
    if (!agentData.agentDescription) newErrors.agentDescription = 'Agent Description is required';
    if (!agentData.agentBusinessContext) newErrors.agentBusinessContext = 'Database Information is required';
    if (!agentData.databaseInfo.server) newErrors['databaseInfo.server'] = 'Server is required';
    if (!agentData.databaseInfo.database) newErrors['databaseInfo.database'] = 'Database is required';
    if (!agentData.databaseInfo.username) newErrors['databaseInfo.username'] = 'Username is required';
    if (!agentData.openai_api_key) newErrors.openai_api_key = 'OpenAI API Key is required';
    // Add more validations as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsSubmitting(true);
    try {
      const stringifiedData = JSON.parse(JSON.stringify(agentData), (key, value) =>
        typeof value === 'number' ? value.toString() : value
      );
      await onSubmit(stringifiedData);
      // Optionally navigate to a success page or show a success message
    } catch (error) {
      console.error('Error submitting agent data', error);
      // Optionally show an error message to the user
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = ['Agent Information', 'Database Information', 'Open AI Specs'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FormSection elevation={0}>
            <FormTitle variant="h6">
              Agent Information
            </FormTitle>
            <TextField
              fullWidth
              label={<>Agent Name </>}
              name="agentName"
              value={agentData.agentName}
              onChange={handleChange}
              inputProps={{ maxLength: 10 }}
              required
              margin="normal"
              helperText={errors.agentName || "Enter a unique name for your agent (max 10 characters)"}
              error={!!errors.agentName}
            />
            <TextField
              fullWidth
              label={<>Agent Code </>}
              name="agentCode"
              value={agentData.agentCode}
              onChange={handleChange}
              inputProps={{ maxLength: 5 }}
              
              disabled={isEditMode}
              margin="normal"
              helperText={errors.agentCode || "A unique 5-character code for your agent"}
              error={!!errors.agentCode}
            />
            <TextField
              fullWidth
              label={<>Agent Description </>}
              name="agentDescription"
              value={agentData.agentDescription}
              onChange={handleChange}
              multiline
              rows={4}
              inputProps={{ maxLength: 500 }}
              required
              margin="normal"
              helperText={errors.agentDescription || "Describe your agent's purpose and capabilities (max 500 characters)"}
              error={!!errors.agentDescription}
            />
            <FormControl fullWidth margin="normal" required error={!!errors.agentEnvironment}>
              <InputLabel>Agent Environment </InputLabel>
              <Select
                name="agentEnvironment"
                value={agentData.agentEnvironment}
                onChange={handleChange}
                disabled={isEditMode}
              >
                <MenuItem value="Test">Test</MenuItem>
                <MenuItem value="Prod">Prod</MenuItem>
              </Select>
              {errors.agentEnvironment && (
                <Typography variant="caption" color="error">
                  {errors.agentEnvironment}
                </Typography>
              )}
            </FormControl>
          </FormSection>
        );
      case 1:
        return (
          <FormSection elevation={0}>
            <FormTitle variant="h6">
              Database Information
            </FormTitle>
            <TextField
              fullWidth
              label={<>Database Information </>}
              name="agentBusinessContext"
              value={agentData.agentBusinessContext}
              onChange={handleChange}
              multiline
              rows={4}
              inputProps={{ maxLength: 1000 }}
              required
              margin="normal"
              helperText={errors.agentBusinessContext || "Describe the database structure and content (max 1000 characters)"}
              error={!!errors.agentBusinessContext}
            />
            <TextField
              fullWidth
              label={<>Server </>}
              name="databaseInfo.server"
              value={agentData.databaseInfo.server}
              onChange={handleChange}
              required
              margin="normal"
              helperText={errors['databaseInfo.server']}
              error={!!errors['databaseInfo.server']}
            />
            <TextField
              fullWidth
              label={<>Database </>}
              name="databaseInfo.database"
              value={agentData.databaseInfo.database}
              onChange={handleChange}
              required
              margin="normal"
              helperText={errors['databaseInfo.database']}
              error={!!errors['databaseInfo.database']}
            />
            <TextField
              fullWidth
              label={<>Username </>}
              name="databaseInfo.username"
              value={agentData.databaseInfo.username}
              onChange={handleChange}
              required
              margin="normal"
              helperText={errors['databaseInfo.username']}
              error={!!errors['databaseInfo.username']}
            />
            <TextField
              fullWidth
              label="Password"
              name="databaseInfo.password"
              type="password"
              value={agentData.databaseInfo.password}
              onChange={handleChange}
              margin="normal"
            />
          </FormSection>
        );
      case 2:
        return (
          <FormSection elevation={0}>
            <FormTitle variant="h6">
              Open AI Specs
            </FormTitle>
            <FormControl fullWidth margin="normal" required error={!!errors.model}>
              <InputLabel>Model </InputLabel>
              <Select
                name="model"
                value={agentData.model}
                onChange={handleChange}
              >
                <MenuItem value="gpt-4">gpt-4</MenuItem>
                <MenuItem value="gpt-3.5-turbo-0125">gpt-3.5-turbo-0125</MenuItem>
                <MenuItem value="gpt-4o-2024-08-06">gpt-4o</MenuItem>

  
              </Select>
              {errors.model && (
                <Typography variant="caption" color="error">
                  {errors.model}
                </Typography>
              )}
            </FormControl>
            <Typography variant="body2" sx={{ mt: 1, mb: 2, color: 'text.secondary' }}>
              Please use the latest model for initial training
            </Typography>
            <TextField
              fullWidth
              label={<>OpenAI API Key </>}
              name="openai_api_key"
              type="password"
              value={agentData.openai_api_key}
              onChange={handleChange}
              required
              margin="normal"
              helperText={errors.openai_api_key || "Enter your OpenAI API key"}
              error={!!errors.openai_api_key}
            />
          </FormSection>
        );
      default:
        return null;
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FormContainer maxWidth="lg">
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 500, color: '#0D0D0D'}}>
          {isEditMode ? 'Edit Agent' : 'Create New Agent'}
        </Typography>
        <Stepper activeStep={activeStep} sx={{ marginBottom: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          {renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              disabled={activeStep === 0 || isSubmitting}
            >
              Back
            </Button>
            <Box>
              <Button
                variant="outlined"
                onClick={() => navigate('/agents')}
                sx={{ marginRight: 1 }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              ) : (
                <Button variant="contained" onClick={handleNext} disabled={isSubmitting} sx={{ color:'white' }}>
                  Next
                </Button>
              )}
            </Box>
          </Box>
        </form>
      </FormContainer>
    </ThemeProvider>
  );
};

export default AgentForm;