import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, styled } from '@mui/material';
import CollectionDetails from './CollectionDetails';
import DatabaseSchemaTraining from './DatabaseSchemaTraining';
import SQLTraining from './SQLTraining';
import GenerateMemory from './GenerateMemory';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTab-root': {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`training-tabpanel-${index}`}
    aria-labelledby={`training-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const TrainingForm = () => {
  const { agentId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Training for Agent {agentId}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={activeTab} onChange={handleChange} aria-label="training tabs">
          <Tab label="Collection Details" />
          <Tab label="Database Schema Training" />
          <Tab label="SQL Training" />
          <Tab label="Generate SQL Memory" />
        </StyledTabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <CollectionDetails agentId={agentId} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <DatabaseSchemaTraining agentId={agentId} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <SQLTraining agentId={agentId} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <GenerateMemory agentId={agentId} />
      </TabPanel>
    </Box>
  );
};

export default TrainingForm;