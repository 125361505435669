import axios from 'axios';
import { loadConfig } from '../../config';

const api = axios.create();

api.interceptors.request.use(async (config) => {
  const appConfig = await loadConfig();
  config.baseURL = appConfig.QueryBudAIAPIs.base_url;
  return config;
});
export const fetchCollectionInfo = async (agentId, userId, collectionParam) => {
  console.log(collectionParam)
  return api.get(`/vector/api/agent/${agentId}/collection_info`, {
    params: { user_id: userId, collection_param: collectionParam }
  });
};
export const fetchData = async (agentId, userId, collectionParam, params = {}) => {
  return api.post(`/vector/api/agent/${agentId}/retrieve_data`, null, {
    params: { user_id: userId, collection_param: collectionParam, ...params }
  });
};

export const publishData = async (agentId, payload) => {
  return api.post(`/vector/api/agent/${agentId}/create_vectorentry`, payload);
};

export const describeTable = async (agentId, tableName, userId) => {
  return api.get(`/vector/api/agent/${agentId}/describe_table?user_id=${userId}`, {
    params: { table_name: tableName}
  });
};

export const generateSQLMemory = async (agentId, tables) => {
  return api.post(`/vector/api/agent/${agentId}/generateSQLMemory`, {
    tables: tables
  });
};

export default api;