import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../CommonComponents/axiosConfig';
import { useLoading } from  '../AgentComponents/Common/LoadingContext'


const CompanyAgents = ({ company }) => {
  const [agents, setAgents] = useState([]);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    fetchCompanyAgents();
    fetchAvailableAgents();
  }, [company]);

  const fetchCompanyAgents = async () => {
    try {
      startLoading();
      const response = await axios.get(`/api/companies/${company.id}/agents`);

      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching company agents:', error);
      }finally{
      stopLoading();
    }
  };

  const fetchAvailableAgents = async () => {
    try {
      startLoading();
      const response = await axios.get('/admin/api/agents');
      const allAgents = response.data;
      const availableAgents = allAgents.filter(agent => !agents.some(a => a.id === agent.id));
      setAvailableAgents(availableAgents);
    } catch (error) {
      console.error('Error fetching available agents:', error);
    }finally{
      stopLoading();
    }
  };

  const handleAddAgent = async (agentId) => {
    try {
      startLoading();
      await axios.post(`/api/companies/${company.id}/agents/${agentId}`);
      fetchCompanyAgents();
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error adding agent to company:', error);
    }finally{
      stopLoading();
    }
  };

  const handleRemoveAgent = async (agentId) => {
    try {
      startLoading();
      await axios.delete(`/api/companies/${company.id}/agents/${agentId}`);
      fetchCompanyAgents();
    } catch (error) {
      console.error('Error removing agent from company:', error);
          }finally{
      stopLoading();
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Company Agents
      </Typography>
      <List>
        {agents.map((agent) => (
          <ListItem key={agent.id}>
            <ListItemText primary={agent.name} />
            <Button onClick={() => handleRemoveAgent(agent.id)} color="error">
              Remove
            </Button>
          </ListItem>
        ))}
      </List>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ mt: 2 }}
      >
        Add Agent
      </Button>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add Agent to Company</DialogTitle>
        <DialogContent>
          <List>
            {availableAgents.map((agent) => (
              <ListItem 
                button 
                key={agent.id} 
                onClick={() => handleAddAgent(agent.agentId)}
              >
                <ListItemText primary={agent.agentCode} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompanyAgents;