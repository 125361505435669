import axios from '../../CommonComponents/axiosConfig';


export const fetchAgents = async () => {
  const response = await axios.get('/admin/api/agents');
  return response.data;
};

export const createAgent = async (agentData, userId) => {

  const response = await axios.post(`/admin/api/agent/?user_id=${userId}`, agentData);
  return response.data;
};

export const updateAgent = async (agentId, agentData, userId) => {
    const response = await axios.put(`/admin/api/agents/${agentId}?user_id=${userId}`, agentData);
    return response.data;
  };
export const deleteAgent = async (agentId, userId) => {

  const response = await axios.delete(`/admin/api/agent/${agentId}/?user_id=${userId}`);
  return response.data;
};

export const fetchAgentById = async (agentId, userId) => {
    const response = await axios.get(`/admin/api/agents/${agentId}?user_id=${userId}`);
    return response.data;
  };