import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Home, Users, Building, UserPlus, LogOut } from 'lucide-react';
import { useAuth } from '../AuthenticationComponents/AuthProvider';

const drawerWidth = 280;

const StyledDrawer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    font: 'Inter, sans-serif',
    width: drawerWidth,
    boxSizing: 'border-box',
    background: 'linear-gradient(180deg, #3a1c71 0%, #2c2c2c 100%)',
    color: 'white',
    height: '100%',
    top: '64px',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
  },
});

const SidebarContent = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
});

const SidebarFooter = styled('div')({
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '10px 0',
  marginBottom: '30px',
});

const StyledNavLink = styled(NavLink)({

  textDecoration: 'none',
  color: 'rgba(255, 255, 255, 0.7)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
});

const StyledListItem = styled(ListItem)({
  padding: '12px 24px',
  marginBottom: '8px',
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '40px',
  color: 'inherit',
});

const StyledListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '16px',
    fontWeight: 500,
  },
});

function Sidebar() {
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    // You might want to add a redirect here
  };

  const menuItems = [
    { to: "/home", icon: <Home size={24} />, text: "Home" },
    { to: "/home/company-admin", icon: <Building size={24} />, text: "Company Administration" },
    { to: "/home/agents", icon: <Users size={24} />, text: "Agents" },
    { to: "/home/user-admin", icon: <UserPlus size={24} />, text: "User Administration" },
  ];

  return (
    <StyledDrawer variant="permanent" anchor="left">
      <SidebarContent>
        <List>
          {menuItems.map((item, index) => (
            <StyledNavLink to={item.to} key={index} end={item.to === "/home"}>
              <StyledListItem button>
                <StyledListItemIcon>
                  {item.icon}
                </StyledListItemIcon>
                <StyledListItemText primary={item.text} />
              </StyledListItem>
            </StyledNavLink>
          ))}
        </List>
      </SidebarContent>
      <SidebarFooter>
        <StyledListItem button onClick={handleLogout}>
          <StyledListItemIcon>
            <LogOut size={24} />
          </StyledListItemIcon>
          <StyledListItemText primary="Logout" />
        </StyledListItem>
      </SidebarFooter>
    </StyledDrawer>
  );
}

export default Sidebar;