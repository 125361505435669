import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import AppsIcon from '@mui/icons-material/Apps';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(90deg, #3a1c71 0%, #d76d77 50%, #ffaf7b 100%)',
  color: 'white',
  padding: '0 16px',
  height: '64px',
  minHeight: '64px',
  maxHeight: '64px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1030,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
}));

const StyledToolbar = styled(Toolbar)({
  minHeight: '64px',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const RightSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const ProductName = styled(Typography)({
  color: 'white',
  fontSize: '20px',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
  marginLeft: '12px',
});

const InstanceName = styled(Typography)({
  color: 'rgba(255, 255, 255, 0.9)',
  fontSize: '16px',
  marginLeft: '12px',
  fontStyle: 'italic',
});

const StyledIconButton = styled(IconButton)({
  color: 'white',
  padding: '8px',
  margin: '0 4px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
  },
});

function Header({ instanceName }) {
  return (
    <StyledAppBar>
      <StyledToolbar>
        <LeftSection>
         
          <ProductName variant="h6" component="span">
            QueryBud AI Console
          </ProductName>
         
        </LeftSection>
        <RightSection>
        <StyledIconButton
            component={Link}
            to="/home"
            title="Home"
          >
            <HomeIcon />
          </StyledIconButton>
          
          <StyledIconButton title="My Account">
            <AccountCircleIcon />
          </StyledIconButton>
        </RightSection>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default Header;