let config = null;

export const loadConfig = async () => {
    if (!config) {
        config = {
            app_name: process.env.REACT_APP_NAME,
            version: process.env.REACT_APP_VERSION,
            BudService: {
                env: process.env.REACT_APP_BudService_ENV,
                user_info_url: process.env.REACT_APP_BudService_USER_INFO_URL,
                refresh_url: process.env.REACT_APP_BudService_REFRESH_URL,
                redirect_url: process.env.REACT_APP_BudService_REDIRECT_URL,
                instance_url: process.env.REACT_APP_BudService_INSTANCE_URL
            },
            QueryBudAIAPIs: {
                base_url: process.env.REACT_APP_QueryBud_AI_APIS_BASE_URL
            }
        };
    }
    return config;
};