import React, { useState, useEffect } from 'react';
import { Box, Grid, Tabs, Tab, Button, useTheme, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import axios from '../CommonComponents/axiosConfig';
import CompanyList from './CompanyList';
import NewCompanyForm from './NewCompanyForm';
import CompanyConfiguration from './CompanyConfiguration';
import CompanyAgents from './CompanyAgents';
import { useLoading } from  '../AgentComponents/Common/LoadingContext'


const CompanyAdministration = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isNewCompanyFormOpen, setIsNewCompanyFormOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { startLoading, stopLoading } = useLoading();
  const theme = useTheme();

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    startLoading();
    try {
      const response = await axios.get(`/api/companies`);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      showSnackbar('Failed to fetch companies', 'error');
    }finally{
    stopLoading();}
  };

  const handleNewCompany = () => {
    setSelectedCompany(null);
    setIsNewCompanyFormOpen(true);
    setActiveTab(0);
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setIsNewCompanyFormOpen(false);
    setActiveTab(0);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCreateCompany = async (companyData) => {
    startLoading();
    try {
      await axios.post(`/api/companies`, companyData);
      fetchCompanies();
      setIsNewCompanyFormOpen(false);
      showSnackbar('Company created successfully', 'success');
    } catch (error) {
      console.error('Error creating company:', error);
      showSnackbar('Failed to create company', 'error');
    }finally{
    stopLoading();}
  };

  const handleUpdateCompany = async (companyId, companyData) => {
    startLoading();
    try {
      await axios.put(`/api/companies/${companyId}`, companyData);
      fetchCompanies();
      showSnackbar('Company updated successfully', 'success');
    } catch (error) {
      console.error('Error updating company:', error);
      showSnackbar('Failed to update company', 'error');
      }finally{
    stopLoading();}
  };

  const handleDeleteCompany = async (companyId) => {
      startLoading();
    try {
      await axios.delete(`/api/companies/${companyId}`);
      fetchCompanies();
      setSelectedCompany(null);
      showSnackbar('Company deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting company:', error);
      showSnackbar('Failed to delete company', 'error');
          }finally{
    stopLoading();}
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <h2>Companies</h2>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={handleNewCompany}
            >
              New Company
            </Button>
          </Box>
          <CompanyList
            companies={companies}
            onSelectCompany={handleCompanySelect}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isNewCompanyFormOpen ? (
            <NewCompanyForm
              onClose={() => setIsNewCompanyFormOpen(false)}
              onSubmit={handleCreateCompany}
            />
          ) : selectedCompany ? (
            <Box>
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                sx={{
                  '& .MuiTab-root': { color: theme.palette.primary.main },
                  '& .Mui-selected': { color: theme.palette.secondary.main },
                  '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main },
                }}
              >
                <Tab label="Configuration" />
                <Tab label="Agents" />
              </Tabs>
              {activeTab === 0 && (
                <CompanyConfiguration 
                  company={selectedCompany}
                  onUpdate={(updatedData) => handleUpdateCompany(selectedCompany.id, updatedData)}
                  onDelete={() => handleDeleteCompany(selectedCompany.id)}
                />
              )}
              {activeTab === 1 && (
                <CompanyAgents company={selectedCompany} />
              )}
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default CompanyAdministration;