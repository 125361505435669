import { useState } from 'react';
import { useLoading } from '../Common/LoadingContext';
import { useAuth } from '../../AuthenticationComponents/AuthProvider';
import { fetchData, publishData,fetchCollectionInfo } from '../Utils/api';

const useTraining = (agentId, collectionParam) => {
  const [entries, setEntries] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [isInfoDialog, setIsInfoDialog] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const { userInfo } = useAuth();

  const handleAddEntry = (entry) => {
    setEntries([...entries, entry]);
  };

  const handleRemoveEntry = (index) => {
    const updatedEntries = [...entries];
    updatedEntries.splice(index, 1);
    setEntries(updatedEntries);
  };

  const handleRemoveAllEntries = () => {
    setEntries([]);
  };

  const handlePublish = async () => {
    setShowConfirmation(false);
    startLoading();
    try {
      console.log(collectionParam)
      const response = await publishData(agentId, userInfo.id, collectionParam, entries);
      setConfirmationMessage(response.data.message);
      setConfirmationAction(() => handleRemoveAllEntries);
      setIsInfoDialog(true);
      setShowConfirmation(true);
    } catch (error) {
      console.error('Error publishing entries:', error);
      setConfirmationMessage('Failed to publish entries.');
      setConfirmationAction(null);
      setIsInfoDialog(true);
      setShowConfirmation(true);
    } finally {
      stopLoading();
    }
  };

  const handleFetchData = async (params = {}) => {
    startLoading();
    try {
      const response = await fetchCollectionInfo(agentId, userInfo.id, collectionParam, params);
      console.log('API response:', response.data);
      setEntries(response.data.data);
      return response.data.data; // Return the data for immediate use
    } catch (error) {
      console.error('Error fetching data:', error);
      setConfirmationMessage('Error fetching data. Please try again.');
      setIsInfoDialog(true);
      setShowConfirmation(true);
      return null;
    } finally {
      stopLoading();
    }
  };

  return {
    entries,
    showConfirmation,
    confirmationMessage,
    confirmationAction,
    isInfoDialog,
    handleAddEntry,
    handleRemoveEntry,
    handleRemoveAllEntries,
    handlePublish,
    handleFetchData,
    setShowConfirmation,
    setConfirmationMessage,
    setConfirmationAction,
    setIsInfoDialog,
  };
};

export default useTraining;