import React, { forwardRef } from 'react';
import { Box, Paper } from '@mui/material';

const ChatHistory = forwardRef(({ messages, onMessageSelect, renderMessageActions, renderMarkdown }, ref) => {
  return (
    <Box ref={ref} sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
      {messages.map((message, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
            mb: 2,
          }}
        >
          <Paper
            elevation={1}
            sx={{
              maxWidth: '70%',
              p: 2,
              borderRadius: 2,
              bgcolor: message.sender === 'user' ? 'primary.light' : 'grey.100',
              cursor: message.sender === 'ai' ? 'pointer' : 'default',
              position: 'relative',
            }}
            onClick={() => message.sender === 'ai' && onMessageSelect(message)}
          >
            {renderMarkdown(message.text)}
            {renderMessageActions && (
              <Box sx={{ position: 'absolute', right: 8, bottom: 8 }}>
                {renderMessageActions(message)}
              </Box>
            )}
          </Paper>
        </Box>
      ))}
    </Box>
  );
});

export default ChatHistory;