import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Grid, Card, CardContent, CardActions, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import useTraining from '../Hooks/useTraining';
import ConfirmationDialog from '../Common/ConfirmationDialog';

const SQLTraining = ({ agentId }) => {
  const [currentEntry, setCurrentEntry] = useState({
    document: '',
    sql_query: '',
    user_score: '',
    LLM_generated_summary_response: '',
    functional_sme_query: '',
    functional_score: '',
    functional_sme_summary_response: ''
  });

  const { 
    entries,
    showConfirmation,
    confirmationMessage,
    confirmationAction,
    isInfoDialog,
    handleAddEntry,
    handleRemoveEntry,
    handleRemoveAllEntries,
    handlePublish,
    setShowConfirmation,
    setConfirmationMessage,
    setConfirmationAction,
    setIsInfoDialog
  } = useTraining(agentId, 'SQL');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentEntry(prev => ({ ...prev, [name]: value }));
  };

  const handleAdd = () => {
    if (currentEntry.document && currentEntry.sql_query && currentEntry.LLM_generated_summary_response) {
      handleAddEntry(currentEntry);
      setCurrentEntry({
        document: '',
        sql_query: '',
        user_score: '',
        LLM_generated_summary_response: '',
        functional_sme_query: '',
        functional_score: '',
        functional_sme_summary_response: ''
      });
    }
  };

  const handlePublishClick = () => {
    setConfirmationMessage("Do you want to publish this data set?");
    setConfirmationAction(() => handlePublish);
    setIsInfoDialog(false);
    setShowConfirmation(true);
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, p: 2 }}>
      <Paper elevation={3} sx={{ flex: 1, p: 2, color: '#6c7581' }}>
        <Typography variant="h6" gutterBottom>Add New Entry</Typography>
        <TextField
          fullWidth
          label="Question"
          name="document"
          value={currentEntry.document}
          onChange={handleInputChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="LLM Generated SQL Query"
          name="sql_query"
          multiline
          rows={4}
          value={currentEntry.sql_query}
          onChange={handleInputChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="LLM Generated Response"
          name="LLM_generated_summary_response"
          multiline
          rows={4}
          value={currentEntry.LLM_generated_summary_response}
          onChange={handleInputChange}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          type="number"
          label="User Score"
          name="user_score"
          value={currentEntry.user_score}
          onChange={handleInputChange}
          margin="normal"
          InputProps={{ inputProps: { min: -1, max: 1, step: 0.1 } }}
        />
        <TextField
          fullWidth
          label="Functional SME Query"
          name="functional_sme_query"
          multiline
          rows={4}
          value={currentEntry.functional_sme_query}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          fullWidth
          type="number"
          label="Functional SME Score (0-10)"
          name="functional_score"
          value={currentEntry.functional_score}
          onChange={handleInputChange}
          margin="normal"
          InputProps={{ inputProps: { min: 0, max: 10, step: 0.1 } }}
        />
        <TextField
          fullWidth
          label="Functional SME Response"
          name="functional_sme_summary_response"
          multiline
          rows={4}
          value={currentEntry.functional_sme_summary_response}
          onChange={handleInputChange}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          disabled={!currentEntry.document || !currentEntry.sql_query || !currentEntry.LLM_generated_summary_response}
          sx={{ mt: 2 }}
        >
          Add Entry
        </Button>
      </Paper>

      <Paper elevation={3} sx={{ flex: 2, p: 2, maxHeight: 'calc(100vh - 100px)', overflowY: 'auto', color: '#6c7581' }}>
        {entries.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleRemoveAllEntries}
              sx={{ mr: 1, borderColor: '#64a7d0', color: '#64a7d0' }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublishClick}
            >
              Publish
            </Button>
          </Box>
        )}
        <Grid container spacing={2}>
          {entries.map((entry, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent sx={{ color: '#6c7581' }}>
                  <Typography variant="h6" gutterBottom>Entry {index + 1}</Typography>
                  <Typography><strong>Question:</strong> {entry.document}</Typography>
                  <Typography><strong>LLM Generated SQL Query:</strong> {entry.sql_query}</Typography>
                  <Typography><strong>LLM Generated Response:</strong> {entry.LLM_generated_summary_response}</Typography>
                  <Typography><strong>User score:</strong> {entry.user_score}</Typography>
                  <Typography><strong>Functional SME Query:</strong> {entry.functional_sme_query}</Typography>
                  <Typography><strong>Functional SME Score:</strong> {entry.functional_score}</Typography>
                  <Typography><strong>Functional SME Response:</strong> {entry.functional_sme_summary_response}</Typography>
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleRemoveEntry(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        {entries.length === 0 && (
          <Typography variant="body1" sx={{ mt: 2 }}>No Records to Display</Typography>
        )}
      </Paper>
      
      <ConfirmationDialog
        open={showConfirmation}
        message={confirmationMessage}
        onConfirm={() => {
          confirmationAction?.();
          setShowConfirmation(false);
        }}
        onCancel={() => setShowConfirmation(false)}
        isInfo={isInfoDialog}
      />
    </Box>
  );
};

export default SQLTraining;