import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Avatar,
  useTheme,
} from '@mui/material';
import { SmartToy } from '@mui/icons-material';
import { useAuth } from '../../AuthenticationComponents/AuthProvider';
import axios from '../../CommonComponents/axiosConfig';
import { useLoading } from '../Common/LoadingContext';

const AgentLandingPage = () => {
  const theme = useTheme();
  const { userInfo } = useAuth();
  const [agents, setAgents] = useState([]);
  const { startLoading, stopLoading } = useLoading();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgents = async () => {
      startLoading();
      try {
        const response = await axios.get(`/api/users/${userInfo.id}/agents?skip=0&limit=100`);
        setAgents(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching agents:', err);
        setError('Failed to fetch agents. Please try again later.');
      } finally {
        stopLoading();
      }
    };

    fetchAgents();
  }, []);

  const initiateSession = async (agentId) => {
    startLoading();
    try {
      const response = await axios.post(`/session/${agentId}`, null, {
        
        params: {
          AppName: 'Text2SQL',
          FirstName: userInfo.firstname,
          LastName: userInfo.lastname,
          email: userInfo.email,
          mmID: userInfo.company,
          ClientId: userInfo.company,
          ClientName: 'abc',
          user_id: userInfo.id
        }
      });

      if (response.status === 200) {
        const sessionData = response.data;
        navigate(`/chat/${agentId}`, { state: { sessionData } });
      }
    } catch (err) {
      console.error('Error initiating session:', err);
      setError('Failed to start chat session. Please try again later.');
    } finally {
      stopLoading();
    }
  };
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const greeting = getGreeting();

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {greeting}, {userInfo?.firstname || 'Guest'}!
      </Typography>
      <Typography variant="h5" gutterBottom>
        Welcome to your AI Agents
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Select an agent to start your conversation
      </Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {agents.map((agent) => (
          <Grid item xs={12} sm={6} md={4} key={agent.agentId}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar
                    sx={{
                      bgcolor: theme.palette.primary.main,
                      width: 56,
                      height: 56,
                      mr: 2,
                    }}
                  >
                    <SmartToy fontSize="large" />
                  </Avatar>
                  <Typography variant="h5" component="div">
                    {agent.name}
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Agent Code: {agent.code}
                </Typography>
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  color="primary"
                  onClick={() => initiateSession(agent.id)}
                >
                  Chat with {agent.name}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AgentLandingPage;