import React from 'react';
import Plot from 'react-plotly.js';
import { Box, Typography } from '@mui/material';

const Graph = ({ data, isMaximized }) => {
  if (!data) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          No graph data available
        </Typography>
      </Box>
    );
  }

  try {
    const plotData = JSON.parse(data);
    const layout = {
      ...plotData.layout,
      autosize: true,
      margin: { l: 50, r: 50, t: 50, b: 50 },
      height: isMaximized ? window.innerHeight - 100 : undefined,
      width: isMaximized ? window.innerWidth - 100 : undefined,
    };

    const config = {
      ...plotData.config,
      responsive: true,
      displayModeBar: isMaximized,
      scrollZoom: isMaximized,
      showLink: false,
      displaylogo: false,
      modeBarButtonsToRemove: ['sendDataToCloud', 'editInChartStudio'],
    };

    return (
      <Plot
        data={plotData.data}
        layout={layout}
        config={config}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
      />
    );
  } catch (error) {
    console.error('Error parsing graph data:', error);
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" color="error">
          Error rendering graph
        </Typography>
      </Box>
    );
  }
};

export default Graph;