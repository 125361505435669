import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../CommonComponents/axiosConfig';
import { useLoading } from '../AgentComponents/Common/LoadingContext';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const { startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('qb_accesstoken');
    if (token) {
      fetchUserInfo(token);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get('/api/user-info', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserInfo(response.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setIsAuthenticated(false);
      setUserInfo(null);
      localStorage.removeItem('qb_accesstoken');
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials) => {
    try {
      startLoading();
      const response = await axios.post('/api/login', credentials);
      const { access_token } = response.data;
      localStorage.setItem('qb_accesstoken', access_token);
      await fetchUserInfo(access_token);
      return true;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    } finally {
      stopLoading();
    }
  };

  const logout = () => {
    localStorage.removeItem('qb_accesstoken');
    setIsAuthenticated(false);
    setUserInfo(null);
    navigate('/login');
  };

  const value = {
    isAuthenticated,
    userInfo,
    login,
    logout,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;