import React, { useState } from 'react';
import AgentForm from './AgentForm';
import { useLoading } from '../Common/LoadingContext';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthenticationComponents/AuthProvider';
import { createAgent } from '../Utils/agentapi';

const NewAgent = () => {
  const { userInfo } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [agentDataToCreate, setAgentDataToCreate] = useState(null);
  const navigate = useNavigate();

  const handleCreateAgent = async (agentData) => {

    setAgentDataToCreate(agentData);
    setDialogProps({
      type: 'confirm',
      message: "This action will create an agent and respective collections in the vector database. Are you sure you want to proceed?",
      onConfirm: confirmCreateAgent,
      onCancel: () => setShowDialog(false)
    });
    setShowDialog(true);
  };

  const confirmCreateAgent = async () => {
    setShowDialog(false);
    startLoading();
    setIsSubmitting(true);
    setFormErrors({});
    try {

      const response = await createAgent(agentDataToCreate, userInfo.id);

      setDialogProps({
        type: 'success',
        message: response.message || 'Agent created successfully!',
        onConfirm: () => navigate('/agents')
      });
      setShowDialog(true);
    } catch (error) {
      console.error("Error creating agent:", error);
      let errorMessage = 'An error occurred while creating the agent. Please try again.';
      if (error.response && error.response.data) {
        if (error.response.data.detail) {
          errorMessage = error.response.data.detail;
        } else if (typeof error.response.data === 'object') {
          setFormErrors(error.response.data);
          errorMessage = 'Please correct the errors in the form.';
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      setDialogProps({
        type: 'error',
        message: errorMessage,
        onConfirm: () => setShowDialog(false)
      });
      setShowDialog(true);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <AgentForm 
        onSubmit={handleCreateAgent} 
        isEditMode={false} 
        isSubmitting={isSubmitting}
        serverErrors={formErrors}
      />
      <ConfirmationDialog 
        open={showDialog} 
        {...dialogProps} 
        onCancel={() => setShowDialog(false)}
      />
    </div>
  );
};

export default NewAgent;