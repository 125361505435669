import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AgentForm from './AgentForm';
import { useLoading } from '../Common/LoadingContext';
import ConfirmationDialog from '../Common/ConfirmationDialog';
import { useAuth } from '../../AuthenticationComponents/AuthProvider';
import { fetchAgentById, updateAgent } from '../Utils/agentapi';

const EditAgent = () => {
  const { userInfo } = useAuth();
  const { agentId } = useParams();
  const [agentData, setAgentData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { loading, startLoading, stopLoading } = useLoading();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const fetchAgentData = async () => {
      startLoading();
      try {
        const data = await fetchAgentById(agentId, userInfo.id);
        setAgentData(data);
      } catch (error) {
        console.error("There was an error fetching the agent data!", error);
        setError("There was an error fetching the agent data.");
      } finally {
        stopLoading();
      }
    };

    fetchAgentData();
  }, [agentId, userInfo.id, startLoading, stopLoading]);

  const handleUpdateAgent = (updatedAgentData) => {

    
    setDialogProps({
      type: 'confirm',
      message: "This action will update the agent and respective collections in the vector database. Are you sure you want to proceed?",
      onConfirm: () => confirmUpdateAgent(updatedAgentData),
      onCancel: () => {

        setShowDialog(false);
      }
    });
    setShowDialog(true);

  };

  const confirmUpdateAgent = async (updatedAgentData) => {
    setShowDialog(false);
    startLoading();
    setIsSubmitting(true);
    setFormErrors({});
    try {

      const response = await updateAgent(agentId, updatedAgentData, userInfo.id);

      
      // Ensure we're passing a string message to the dialog
      let successMessage = 'Agent updated successfully!';
      if (typeof response === 'object' && response.message) {
        successMessage = response.message;
      } else if (typeof response === 'string') {
        successMessage = response;
      }
      
      setDialogProps({
        type: 'success',
        message: successMessage,
        onConfirm: () => navigate('/agents')
      });
      setShowDialog(true);
    } catch (error) {
      console.error("Error updating agent:", error);
      let errorMessage = 'An error occurred while updating the agent. Please try again.';
      if (error.response && error.response.data) {
        if (typeof error.response.data === 'object') {
          if (error.response.data.detail) {
            errorMessage = error.response.data.detail;
          } else {
            // Handle case where error.response.data is an object with multiple fields
            errorMessage = Object.values(error.response.data).join('. ');
            setFormErrors(error.response.data);
          }
        } else if (typeof error.response.data === 'string') {
          errorMessage = error.response.data;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }
      setDialogProps({
        type: 'error',
        message: errorMessage,
        onConfirm: () => setShowDialog(false)
      });
      setShowDialog(true);
    } finally {
      stopLoading();
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="edit-agent-container">
      <h1>Edit Agent</h1>
      {agentData && (
        <AgentForm
          key={agentData.agentId}
          initialValues={agentData}
          onSubmit={handleUpdateAgent}
          isEditMode={true}
          isSubmitting={isSubmitting}
          serverErrors={formErrors}
        />
      )}
      <ConfirmationDialog 
        open={showDialog} 
        {...dialogProps} 
      />
    </div>
  );
};

export default EditAgent;